const get_started = {
    page_title: 'Get started',
    title: 'Something to help you succeed',
    subtitle: 'A few things you can do to quickly get value of Logto',
    develop: {
        title: 'Develop: Take 5 minutes to integrate your app',
    },
    customize: {
        title: 'Customize: Deliver a great sign-in experience',
        preview: {
            title: 'Check the live preview of the sign-in experience you just customized',
            subtitle: 'Try Logto sign-in experience now to see how it works',
        },
        connector: {
            title: 'Add more connectors to support more social sign-in methods',
            subtitle: 'Try passwordless sign in and enable a secure and frictionless experience for your customers',
        },
        continue_customizing: 'Continue customizing',
        try_now: 'Try now',
        add_more: 'Add more',
    },
    secure: {
        title: 'Secure: Protect your resources',
    },
    manage: {
        title: 'Manage: Define access control for your product and users',
        rbac: {
            title: 'Add role-based access control to protect your resources',
            subtitle: 'Control your resource through scalable role authorization for diverse use cases.',
        },
        create_roles: 'Create roles',
    },
    view_all: 'View all →',
};
export default Object.freeze(get_started);
