const enterprise_sso = {
    page_title: 'SSO Empresarial',
    title: 'SSO Empresarial',
    subtitle: 'Conecte o fornecedor de identidade empresarial e ative o Logon Único iniciado pelo SP.',
    create: 'Adicionar conector empresarial',
    col_connector_name: 'Nome do conector',
    col_type: 'Tipo',
    col_email_domain: 'Domínio do email',
    col_status: 'Estado',
    col_status_in_use: 'Em uso',
    col_status_invalid: 'Inválido',
    placeholder_title: 'Conector empresarial',
    placeholder_description: 'O Logto forneceu muitos provedores de identidade empresarial integrados para conectar, ao mesmo tempo, você pode criar o seu próprio com protocolos padrão.',
    create_modal: {
        title: 'Adicionar conector empresarial',
        text_divider: 'Ou você pode personalizar seu conector por um protocolo padrão.',
        connector_name_field_title: 'Nome do conector',
        connector_name_field_placeholder: 'Nome do provedor de identidade empresarial',
        create_button_text: 'Criar conector',
    },
};
export default Object.freeze(enterprise_sso);
