const organization_details = {
    page_title: '조직 세부 정보',
    delete_confirmation: '삭제하면 모든 회원이 이 조직에서 멤버십과 역할을 잃게 됩니다. 이 작업은 취소할 수 없습니다.',
    organization_id: '조직 ID',
    settings_description: '조직은 일반적으로 사용자로 인식되는 일련의 ID에 의해 인식되는 ID 그룹입니다.\n\n각 조직에는 해당 멤버, 역할 및 권한이 있지만 역할 및 권한은 조직 템플릿에 의해 정의됩니다.',
    name_placeholder: '조직의 이름, 고유할 필요는 없습니다.',
    description_placeholder: '조직에 대한 설명입니다.',
    member: '멤버',
    member_other: '멤버',
    add_members_to_organization: '조직 {{name}}에 멤버 추가',
    add_members_to_organization_description: '이름, 이메일, 전화 또는 사용자 ID를 검색하여 적절한 사용자를 찾습니다. 기존 멤버는 검색 결과에 표시되지 않습니다.',
    add_with_organization_role: '조직 역할로 추가',
    user: '사용자',
    authorize_to_roles: '{{name}}에게 다음 역할에 대한 액세스 권한 부여:',
    edit_organization_roles: '조직 역할 편집',
    edit_organization_roles_of_user: '{{name}}의 조직 역할 편집',
    remove_user_from_organization: '사용자를 조직에서 제거',
    remove_user_from_organization_description: '제거하면 사용자가 이 조직에서 멤버십과 역할을 잃습니다. 이 작업은 취소할 수 없습니다.',
    search_user_placeholder: '이름, 이메일, 전화 또는 사용자 ID로 검색',
    at_least_one_user: '최소한 한 명의 사용자가 필요합니다.',
};
export default Object.freeze(organization_details);
