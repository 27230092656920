const organizations = {
    page_title: 'Организации',
    title: 'Организации',
    subtitle: 'Представляют команды, бизнес-клиентов и партнерские компании, которые получают доступ к вашим приложениям в качестве организаций.',
    organization_id: 'Идентификатор организации',
    members: 'Участники',
    create_organization: 'Создать организацию',
    setup_organization: 'Настройка вашей организации',
    organization_list_placeholder_title: 'Организация',
    organization_list_placeholder_text: 'Организация обычно используется в приложениях с мультиарендой или похожих на мультиаренду SaaS. Функция "Организации" позволяет вашим B2B-клиентам лучше управлять своими партнерами и клиентами, а также настраивать способы доступа конечных пользователей к их приложениям.',
    organization_name_placeholder: 'Моя организация',
    organization_description_placeholder: 'Краткое описание организации',
    organization_permission: 'Разрешение организации',
    organization_permission_other: 'Разрешения организации',
    organization_permission_description: 'Разрешение организации относится к разрешению доступа к ресурсу в контексте организации. Разрешение организации должно быть представлено в виде осмысленной строки и также служить именем и уникальным идентификатором.',
    organization_permission_delete_confirm: 'Если это разрешение будет удалено, все роли организации, включая это разрешение, потеряют это разрешение, и пользователи, у которых было это разрешение, потеряют предоставленный им доступ к нему.',
    create_permission_placeholder: 'Чтение истории назначений',
    permission: 'Разрешение',
    permission_other: 'Разрешения',
    organization_role: 'Роль организации',
    organization_role_other: 'Роли организации',
    organization_role_description: 'Роль организации - это группировка разрешений, которые могут быть назначены пользователям. Разрешения должны быть взяты из предопределенных разрешений организации.',
    organization_role_delete_confirm: 'При этом будут удалены разрешения, связанные с этой ролью, у затронутых пользователей, и будут удалены отношения между ролями организации, участниками в организации и разрешениями организации.',
    role: 'Роль',
    create_role_placeholder: 'Пользователи с правами только для просмотра',
    search_placeholder: 'Поиск по названию организации или ID',
    search_permission_placeholder: 'Начните вводить для поиска и выбора разрешений',
    search_role_placeholder: 'Начните вводить для поиска и выбора ролей',
    guide: {
        title: 'Начать с руководств',
        subtitle: 'Запустите процесс разработки вашего приложения с нашими руководствами',
        introduction: {
            section_1: {
                title: 'Сначала разберемся, как работают организации в Logto',
                description: 'В многомандатных SaaS-приложениях мы часто создаем несколько организаций с одним и тем же набором разрешений и ролей, но в контексте организации это может играть важную роль в управлении различными уровнями доступа. Подумайте о каждом арендаторе, как о организации Logto, и они естественным образом делят один и тот же шаблон управления доступом. Мы называем это "шаблоном организации".',
            },
            section_2: {
                title: 'Шаблон организации состоит из двух частей',
                organization_permission_description: 'Разрешение организации относится к разрешению доступа к ресурсу в контексте организации. Разрешение организации должно быть представлено в виде осмысленной строки и также служить именем и уникальным идентификатором.',
                organization_role_description: 'Роль организации - это группировка разрешений, которые могут быть назначены пользователям. Разрешения должны быть взяты из предопределенных разрешений организации.',
            },
            section_3: {
                title: 'Взаимодействие с иллюстрацией для просмотра связей',
                description: 'Давайте рассмотрим пример. Джон, Сара и Тони находятся в разных организациях с различными ролями в контексте разных организаций. Наведитесь на различные модули и посмотрите, что происходит.',
            },
        },
        step_1: 'Шаг 1: Определите разрешения организаций',
        step_2: 'Шаг 2: Определите роли организаций',
        step_2_description: '"Роли организаций" представляют собой набор ролей, предоставленных каждой организации с самого начала. Эти роли определяются глобальными разрешениями, установленными на предыдущем экране. Как и с разрешением организации, когда вы закончите эту настройку в первый раз, вам не потребуется делать это каждый раз, когда вы создаете новую организацию.',
        step_3: 'Шаг 3: Создайте свою первую организацию',
        step_3_description: 'Давайте создадим вашу первую организацию. Она поставляется с уникальным идентификатором и служит контейнером для обработки различных более бизнес-направленных идентификаторов, таких как партнеры, клиенты и их контроль доступа.',
        more_next_steps: 'Еще шаги',
        add_members: 'Добавить участников в вашу организацию',
        add_members_action: 'Массовое добавление участников и назначение ролей',
        add_enterprise_connector: 'Добавить корпоративную SSO',
        add_enterprise_connector_action: 'Настройте корпоративную SSO',
        organization_permissions: 'Разрешения организации',
        permission_name: 'Название разрешения',
        permissions: 'Разрешения',
        organization_roles: 'Роли организации',
        role_name: 'Название роли',
        organization_name: 'Название организации',
        admin: 'Администратор',
        admin_description: 'Роль "Администратор" использует тот же шаблон организации в различных организациях.',
        member: 'Участник',
        member_description: 'Роль "Участник" использует тот же шаблон организации в различных организациях.',
        guest: 'Гость',
        guest_description: 'Роль "Гость" использует тот же шаблон организации в различных организациях.',
        create_more_roles: 'Вы можете создавать дополнительные роли в настройках шаблона организации. Эти роли организации будут применяться в различных организациях.',
        read_resource: 'чтение:ресурс',
        edit_resource: 'редактирование:ресурс',
        delete_resource: 'удаление:ресурс',
        ellipsis: '……',
        johnny: 'Джонни принадлежит к двум организациям с адресом электронной почты "john@email.com" в качестве единственного идентификатора. Он является администратором организации А, а также гостем организации B.',
        sarah: 'Сара принадлежит одной организации с адресом электронной почты "sarah@email.com" в качестве единственного идентификатора. Она является администратором организации Б.',
        tony: 'Тони принадлежит одной организации с адресом электронной почты "tony@email.com" в качестве единственного идентификатора. Он является участником организации C.',
    },
};
export default Object.freeze(organizations);
