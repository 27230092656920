const enterprise_sso = {
    page_title: 'Kurumsal SSO',
    title: 'Kurumsal SSO',
    subtitle: 'Kurumsal kimlik sağlayıcısını bağlayın ve SP başlatmalı Tek Oturum Açmayı etkinleştirin.',
    create: 'Kurumsal bağlayıcı ekle',
    col_connector_name: 'Bağlayıcı adı',
    col_type: 'Tür',
    col_email_domain: 'E-posta etki alanı',
    col_status: 'Durum',
    col_status_in_use: 'Kullanılıyor',
    col_status_invalid: 'Geçersiz',
    placeholder_title: 'Kurumsal bağlayıcı',
    placeholder_description: 'Logto, birçok yerleşik kurumsal kimlik sağlayıcı sunmuştur, aynı zamanda standart protokollerle kendi kimlik sağlayıcınızı oluşturabilirsiniz.',
    create_modal: {
        title: 'Kurumsal bağlayıcı ekle',
        text_divider: 'Ya da standart bir protokol ile bağlayıcınızı özelleştirebilirsiniz.',
        connector_name_field_title: 'Bağlayıcı adı',
        connector_name_field_placeholder: 'Kurumsal kimlik sağlayıcı için isim',
        create_button_text: 'Bağlayıcı Oluştur',
    },
};
export default Object.freeze(enterprise_sso);
