const enterprise_sso = {
    page_title: '기업 SSO',
    title: '기업 SSO',
    subtitle: '기업 신원 공급자를 연결하고 SP-initiated 단일 로그인을 활성화합니다.',
    create: '기업 커넥터 추가',
    col_connector_name: '커넥터 이름',
    col_type: '유형',
    col_email_domain: '이메일 도메인',
    col_status: '상태',
    col_status_in_use: '사용 중',
    col_status_invalid: '유효하지 않음',
    placeholder_title: '기업 커넥터',
    placeholder_description: 'Logto는 많은 내장 기업 신원 공급자를 제공했으며, 동시에 표준 프로토콜로 사용자 정의할 수 있습니다.',
    create_modal: {
        title: '기업 커넥터 추가',
        text_divider: '또는 표준 프로토콜을 사용하여 커넥터를 사용자 정의할 수 있습니다.',
        connector_name_field_title: '커넥터 이름',
        connector_name_field_placeholder: '기업 신원 공급자의 이름',
        create_button_text: '커넥터 생성',
    },
};
export default Object.freeze(enterprise_sso);
