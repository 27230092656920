const enterprise_sso = {
    page_title: 'Jednolite logowanie dla przedsiębiorstw',
    title: 'Jednolite logowanie dla przedsiębiorstw',
    subtitle: 'Połącz dostawcę tożsamości przedsiębiorstwa i włącz jednostronne logowanie jednolitego.',
    create: 'Dodaj łącznik przedsiębiorstwa',
    col_connector_name: 'Nazwa łącznika',
    col_type: 'Typ',
    col_email_domain: 'Domena e-mail',
    col_status: 'Status',
    col_status_in_use: 'W użyciu',
    col_status_invalid: 'Nieprawidłowy',
    placeholder_title: 'Łącznik przedsiębiorstwa',
    placeholder_description: 'Logto udostępniło wiele wbudowanych dostawców tożsamości przedsiębiorstw, możesz także stworzyć własnego zgodnego ze standardowymi protokołami.',
    create_modal: {
        title: 'Dodaj łącznik przedsiębiorstwa',
        text_divider: 'Albo możesz dostosować swój łącznik za pomocą standardowego protokołu.',
        connector_name_field_title: 'Nazwa łącznika',
        connector_name_field_placeholder: 'Nazwa dostawcy tożsamości przedsiębiorstwa',
        create_button_text: 'Stwórz łącznik',
    },
};
export default Object.freeze(enterprise_sso);
