const guide = {
    start_building: 'Start building',
    get_started: 'Get started',
    categories: {
        featured: 'Popular and for you',
        Traditional: 'Traditional web app',
        SPA: 'Single page app',
        Native: 'Native',
        MachineToMachine: 'Machine-to-machine',
    },
    filter: {
        title: 'Filter framework',
        placeholder: 'Search for framework',
    },
    checkout_tutorial: 'Checkout {{name}} tutorial',
    do_not_need_tutorial: "If you don't need a tutorial, you can continue without a framework guide",
    finish_and_done: 'Finish and done',
    cannot_find_guide: "Can't find your guide?",
    describe_guide_looking_for: 'Describe the guide you are looking for',
    request_guide_successfully: 'Your request has been successfully submitted. Thank you!',
    app: {
        select_framework_or_tutorial: 'Select a framework or tutorial',
        guide_modal_title: 'Start with SDK and guides',
        modal_subtitle: 'Jumpstart your app development process with our pre-built SDK and tutorials.',
        select_a_framework: 'Select a framework',
        continue_without_framework: 'Create app without framework',
        describe_guide_looking_for_placeholder: 'E.g., I want to integrate Logto into my Angular app.',
    },
    api: {
        modal_title: 'Start with tutorials',
        modal_subtitle: 'Jumpstart your app development process with our pre-built tutorials.',
        select_a_tutorial: 'Select a tutorial',
        continue_without_tutorial: 'Continue without tutorial',
        describe_guide_looking_for_placeholder: 'E.g., I want to protect my API using deno.',
    },
};
export default Object.freeze(guide);
