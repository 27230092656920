const organization_details = {
    page_title: 'Detalles de la organización',
    delete_confirmation: 'Una vez eliminada, todos los miembros perderán su membresía y roles en esta organización. Esta acción no se puede deshacer.',
    organization_id: 'ID de la organización',
    settings_description: 'Una organización es un grupo de identidades (generalmente usuarios) que se reconocen por un identificador común.\n\nCada organización tiene su propio conjunto de miembros, roles y permisos, mientras que los roles y permisos son definidos por la plantilla de la organización.',
    name_placeholder: 'El nombre de la organización, no es necesario que sea único.',
    description_placeholder: 'Una descripción de la organización.',
    member: 'Miembro',
    member_other: 'Miembros',
    add_members_to_organization: 'Agregar miembros a la organización {{name}}',
    add_members_to_organization_description: 'Encuentra usuarios apropiados buscando nombre, correo electrónico, teléfono o ID de usuario. Los miembros existentes no se muestran en los resultados de búsqueda.',
    add_with_organization_role: 'Agregar con rol(es) de organización',
    user: 'Usuario',
    authorize_to_roles: 'Autorizar a {{name}} para acceder a los siguientes roles:',
    edit_organization_roles: 'Editar roles de organización',
    edit_organization_roles_of_user: 'Editar roles de organización de {{name}}',
    remove_user_from_organization: 'Eliminar usuario de la organización',
    remove_user_from_organization_description: 'Una vez eliminado, el usuario perderá su membresía y roles en esta organización. Esta acción no se puede deshacer.',
    search_user_placeholder: 'Buscar por nombre, correo electrónico, teléfono o ID de usuario',
    at_least_one_user: 'Se requiere al menos un usuario.',
};
export default Object.freeze(organization_details);
