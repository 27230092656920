const enterprise_sso = {
    page_title: 'SSO de la empresa',
    title: 'SSO de la empresa',
    subtitle: 'Conecta el proveedor de identidad de la empresa y habilita el inicio de sesión único iniciado por el proveedor de servicios.',
    create: 'Agregar conector de la empresa',
    col_connector_name: 'Nombre del conector',
    col_type: 'Tipo',
    col_email_domain: 'Dominio de correo electrónico',
    col_status: 'Estado',
    col_status_in_use: 'En uso',
    col_status_invalid: 'Inválido',
    placeholder_title: 'Conector de la empresa',
    placeholder_description: 'Logto ha proporcionado muchos proveedores de identidad empresariales integrados para conectarse, al mismo tiempo, puedes crear el tuyo propio con protocolos estándar.',
    create_modal: {
        title: 'Agregar conector de la empresa',
        text_divider: 'O puedes personalizar tu conector con un protocolo estándar.',
        connector_name_field_title: 'Nombre del conector',
        connector_name_field_placeholder: 'Nombre para el proveedor de identidad empresarial',
        create_button_text: 'Crear conector',
    },
};
export default Object.freeze(enterprise_sso);
