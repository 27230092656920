@use '@/scss/underscore' as _;

.title {
  font: var(--font-title-3);
}

.hint {
  font: var(--font-body-2);
  margin-top: _.unit(3);

  .strong {
    font: var(--font-label-2);
  }

  ol {
    padding-inline-start: _.unit(4);
  }
}
