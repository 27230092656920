@use '@/scss/underscore' as _;

.addButton {
  margin-top: _.unit(3);

  svg {
    color: var(--color-text-secondary);
  }
}

.spinner {
  margin: _.unit(2) 0;
}
