@use '@/scss/underscore' as _;

.deleteConfirm {
  > :not(:first-child) {
    margin-top: _.unit(6);
  }

  .description {
    font: var(--font-body-2);
  }

  .highlight {
    color: var(--color-primary-50);
  }
}

.textField {
  @include _.form-text-field;
}

.customEndpointNotes {
  margin-top: _.unit(6);
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}
