const organization_details = {
    page_title: "Dettagli dell'organizzazione",
    delete_confirmation: "Una volta eliminato, tutti i membri perderanno la loro iscrizione e ruoli in questa organizzazione. Quest'azione non può essere annullata.",
    organization_id: 'ID organizzazione',
    settings_description: "Un'organizzazione è un gruppo di identità (di solito utenti) riconosciute da un identificatore comune.\n\nOgni organizzazione ha il suo insieme di membri, ruoli e autorizzazioni, mentre i ruoli e le autorizzazioni sono definiti dal modello di organizzazione.",
    name_placeholder: "Il nome dell'organizzazione, non è necessario che sia univoco.",
    description_placeholder: "Una descrizione dell'organizzazione.",
    member: 'Membro',
    member_other: 'Membri',
    add_members_to_organization: "Aggiungi membri all'organizzazione {{name}}",
    add_members_to_organization_description: 'Trova gli utenti appropriati cercando per nome, email, telefono o ID utente. Gli utenti già esistenti non vengono mostrati nei risultati della ricerca.',
    add_with_organization_role: "Aggiungi con ruolo(i) dell'organizzazione",
    user: 'Utente',
    authorize_to_roles: 'Autorizza {{name}} ad accedere ai seguenti ruoli:',
    edit_organization_roles: "Modifica ruoli dell'organizzazione",
    edit_organization_roles_of_user: "Modifica ruoli dell'organizzazione di {{name}}",
    remove_user_from_organization: "Rimuovi utente dall'organizzazione",
    remove_user_from_organization_description: "Una volta rimosso, l'utente perderà la sua iscrizione e i ruoli in questa organizzazione. Quest'azione non può essere annullata.",
    search_user_placeholder: 'Cerca per nome, email, telefono o ID utente',
    at_least_one_user: 'È richiesto almeno un utente.',
};
export default Object.freeze(organization_details);
