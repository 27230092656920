const enterprise_sso = {
    page_title: 'Единый вход в предприятие',
    title: 'Единый вход в предприятие',
    subtitle: 'Подключите поставщика идентификации предприятия и включите одностороннюю аутентификацию с инициацией службы.',
    create: 'Добавить предприятий коннектор',
    col_connector_name: 'Имя коннектора',
    col_type: 'Тип',
    col_email_domain: 'Домен электронной почты',
    col_status: 'Статус',
    col_status_in_use: 'Используется',
    col_status_invalid: 'Недопустимый',
    placeholder_title: 'Коннектор предприятия',
    placeholder_description: 'Logto предоставил множество встроенных поставщиков идентификации предприятия для подключения, тем временем вы можете создать своего с использованием стандартных протоколов.',
    create_modal: {
        title: 'Добавить коннектор предприятия',
        text_divider: 'Или вы можете настроить свой коннектор по стандартному протоколу.',
        connector_name_field_title: 'Имя коннектора',
        connector_name_field_placeholder: 'Имя для поставщика идентификации предприятия',
        create_button_text: 'Создать коннектор',
    },
};
export default Object.freeze(enterprise_sso);
