const enterprise_sso = {
    page_title: 'Enterprise SSO',
    title: 'Enterprise SSO',
    subtitle: 'Verbinden Sie den Unternehmensidentitätsanbieter und aktivieren Sie das SP-gesteuerte Single Sign-On.',
    create: 'Unternehmensconnector hinzufügen',
    col_connector_name: 'Connector-Name',
    col_type: 'Typ',
    col_email_domain: 'E-Mail-Domain',
    col_status: 'Status',
    col_status_in_use: 'In Verwendung',
    col_status_invalid: 'Ungültig',
    placeholder_title: 'Unternehmensconnector',
    placeholder_description: 'Logto hat viele integrierte Unternehmensidentitätsanbieter zur Verbindung bereitgestellt. Gleichzeitig können Sie Ihren eigenen Anbieter mit Standardprotokollen erstellen.',
    create_modal: {
        title: 'Unternehmensconnector hinzufügen',
        text_divider: 'Oder Sie können Ihren Connector mit einem Standardprotokoll anpassen.',
        connector_name_field_title: 'Connector-Name',
        connector_name_field_placeholder: 'Name für den Unternehmensidentitätsanbieter',
        create_button_text: 'Connector erstellen',
    },
};
export default Object.freeze(enterprise_sso);
